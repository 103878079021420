import React, { Component } from 'react';
import axios from 'axios';
import jump from 'jump.js';
import {
  FaEnvelope as Email,
  FaHeart as Heart,
  FaFacebook as Facebook,
  FaLinkedin as Linkedin,
  FaTrash as Delete,
  FaTwitter as Twitter
} from 'react-icons/fa';
import { IoMdDownload as Download } from 'react-icons/io';

// the 7 deep questions to get to your why
const questions = [
  'What do you want to do?',
  'Why is that important to you?',
  'Why is that important to you?',
  'Why is that important to you?',
  'Why is that important to you?',
  'Why is that important to you?',
  'Why is that important to you?'
];

const download = exercise => {
  let text = '';

  questions.forEach((q, index) => {
    text += `${q}\n`;
    if (exercise.answers.length - 1 >= index) {
      text += `${exercise.answers[index]}\n\n`;
    }
  });

  const file = new Blob([text], { type: 'text/plain' });
  const element = document.createElement('a');
  if (element.download !== undefined) {
    element.href = URL.createObjectURL(file);
    element.download = '7LevelsDeep.txt';
    element.style.visibility = 'hidden';
    document.body.appendChild(element);
    element.click();
  }
};

// sets state to persistent state or initial state
const initialState = {
  exercises: [],
  exercise: {
    date: Date.now(),
    answers: ['']
  },
  currentAnswer: 0,
  answerError: '',
  started: false,
  editing: false,
  userCount: 0,
};

export default class Home extends Component {
  constructor(props) {
    super(props);

    let foundState;
    if (global.window) {
      foundState = JSON.parse(window.localStorage.getItem('levelState')) || initialState;
    }

    this.state = {
      ...(foundState || initialState),
      hasMounted: false,
    };

    // bind functions to this
    this.onEnter = this.onEnter.bind(this);
    this.removeOnEnter = this.removeOnEnter.bind(this);
    this.startExercise = this.startExercise.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.changeAnswer = this.changeAnswer.bind(this);
    this.setCurrent = this.setCurrent.bind(this);
    this.next = this.next.bind(this);
    this.loadExercise = this.loadExercise.bind(this);
    this.deleteExercise = this.deleteExercise.bind(this);
  }

  // runs after initial mount
  componentDidMount() {
    if (!this.state.started) {
      document.getElementById('0').addEventListener('focus', this.removeOnEnter); // adds event listener for focus on first answer
      document.addEventListener('keypress', this.onEnter); // global event which will focus on first answer if enter button pressed
    }

    const state = JSON.parse(window.localStorage.getItem('levelState')) || initialState;
    this.setState({
      ...state,
      hasMounted: true
    });

    const exercise = state.exercise;
    const index = exercise.answers.findIndex(answer => answer === ''); // gets first blank answer index
    if (index !== -1) {
      const answers = exercise.answers.slice(0, index + 1); // removes answers past the first blank answer
      // removes blank answers from state
      this.setState(
        {
          exercise: {
            ...exercise,
            answers: answers
          },
          currentAnswer: index
        },
        () => {
          this.next({ preventDefault: () => {} }, index - 1);
        }
      );
    }

    // set height and value of each answer
    const answers = document.querySelectorAll('.answer');
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i];
      answer.style.height = answer.scrollHeight + 'px';
      const val = answer.value;
      answer.value = '';
      answer.value = this.capitalizeFirstLetter(val);
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // run checks to see if component should update
  //   const state = this.state;
  //   if (state.exercises !== nextState.exercises) {
  //     return true;
  //   }
  //   if (state.exercise.answers !== nextState.exercise.answers) {
  //     return true;
  //   }
  //   if (state.currentAnswer !== nextState.currentAnswer) {
  //     return true;
  //   }
  //   if (state.started !== nextState.started) {
  //     return true;
  //   }
  //   if (state.editing !== nextState.editing) {
  //     return true;
  //   }
  //   return false;
  // }

  isMobile() {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  // runs after each update of state
  componentDidUpdate() {
    localStorage.setItem('levelState', JSON.stringify(this.state)); // persists state
    // set height and value of each answer
    const answers = document.querySelectorAll('.answer');
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i];
      answer.style.height = answer.scrollHeight + 'px';
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // runs when enter button is originally pressed to start exercise
  onEnter(e) {
    e.preventDefault();
    if (e.keyCode === 13) {
      // if enter button pressed
      this.startExercise(); // start exercise
      document.removeEventListener('keypress', this.onEnter); // remove onEnter event listener
    }
  }

  // removes onEnver event listener on focus of first answer
  removeOnEnter() {
    document.removeEventListener('keypress', this.onEnter); // remove onEnter event listener
    document.getElementById('0').removeEventListener('focus', this.removeOnEnter); // remove focus event listener from first answer input
  }

  // focuses on element
  focusElem(id) {
    const elem = document.getElementById(id);
    elem.focus();
  }

  // starts exercise
  startExercise() {
    // create new exercise and sets started to true
    this.setState({
      exercise: {
        date: Date.now(),
        answers: ['']
      },
      started: true,
      editing: false
    });
    jump(document.getElementById('exercise'), {
      duration: 1000,
      callback: () => this.focusElem('0')
    });
    this.removeOnEnter(); // remove onEnter event listener
  }

  // sets currentAnser index to the focused answer's index
  setCurrent(e, index) {
    e.preventDefault();
    this.setState({
      currentAnswer: index
    });
    const elem = document.getElementById(index);
    if (elem) {
      elem.style.height = elem.scrollHeight + 'px';
      const val = elem.value;
      elem.value = '';
      elem.value = this.capitalizeFirstLetter(val);
    }
  }

  // handle when key down on text area is enter button
  handleEnter(e, index) {
    if (e.keyCode === 13) {
      // enter button keyCode = 13
      this.next(e, index);
    }
  }

  // runs when answer is changed
  changeAnswer(e, index) {
    e.preventDefault();
    this.setState({
      exercise: {
        ...this.state.exercise,
        answers: [
          ...this.state.exercise.answers.slice(0, index),
          this.capitalizeFirstLetter(e.target.value),
          ...this.state.exercise.answers.slice(index + 1)
        ]
      },
      currentAnswer: index
    });
    const elem = document.getElementById(index);
    elem.style.height = elem.scrollHeight + 'px';
  }

  // runs on enter when focused on answer
  next(e, index) {
    e.preventDefault(); // prevent default event
    const state = this.state;
    const currentAnswer = state.currentAnswer;
    const exercise = state.exercise;
    let answers = exercise.answers;
    const answer = answers[currentAnswer];
    if (answer === '') {
      // if current answer is empty
      this.focusElem(currentAnswer.toString());
      return;
    }
    const answersLength = answers.length;
    if (index === 6) {
      // last button clicked
      let exercises = state.exercises;
      if (!state.editing) {
        // not editing
        axios.post('/api/count');
        this.setState(
          {
            exercises: exercises.concat([exercise]),
            exercise: {
              date: Date.now(),
              answers: ['']
            },
            started: false,
            currentAnswer: 0
          },
          () => {
            const pastElement = document.getElementById('past');
            if (pastElement) {
              jump(document.getElementById('past'), {
                duration: 700
              });
            }
          }
        );
      } else {
        // we are editing
        const editIndex = exercises.findIndex(e => e.date === exercise.date); // find exercise with the same date
        if (editIndex === -1) {
          // could not find the exercise we were editing
          this.setState(
            {
              exercises: exercises.concat([exercise]),
              exercise: {
                date: Date.now(),
                answers: ['']
              },
              started: false,
              editing: false,
              currentAnswer: 0
            },
            () => {
              const pastElement = document.getElementById('past');
              if (pastElement) {
                jump(document.getElementById('past'), {
                  duration: 700
                });
              }
            }
          );
        } else {
          // add new exercise to exercises and reset exercise
          exercises = [
            ...exercises.slice(0, editIndex),
            exercise,
            ...exercises.slice(editIndex + 1)
          ];
          this.setState(
            {
              exercises: exercises,
              exercise: {
                date: Date.now(),
                answers: ['']
              },
              started: false,
              editing: false,
              currentAnswer: 0
            },
            () => {
              const pastElement = document.getElementById('past');
              if (pastElement) {
                jump(document.getElementById('past'), {
                  duration: 700
                });
              }
            }
          );
        }
      }
    } else {
      // not on last question
      if (this.isMobile()) {
        if (currentAnswer === answersLength - 1) {
          // no answers ahead of the current answer
          answers = answers.concat(['']);
          this.setState(
            {
              exercise: {
                ...exercise,
                answers: answers
              },
              currentAnswer: answersLength
            },
            () => this.focusElem(answersLength.toString())
          );
          return;
        } else {
          // current answer is not the last answer answered
          const nextAnswer = currentAnswer + 1;
          this.setState(
            {
              currentAnswer: currentAnswer + 1
            },
            () => this.focusElem(nextAnswer)
          );
        }
      } else {
        if (currentAnswer === answersLength - 1) {
          // no answers ahead of the current answer
          answers = answers.concat(['']);
          this.setState({
            exercise: {
              ...exercise,
              answers: answers
            },
            currentAnswer: answersLength
          });
          jump(document.getElementById('answer' + currentAnswer), {
            duration: 700,
            callback: () => this.focusElem(answersLength.toString())
          });
          return;
        } else {
          // current answer is not the last answer answered
          const nextAnswer = currentAnswer + 1;
          this.setState({
            currentAnswer: currentAnswer + 1
          });
          const Id = 'answer' + currentAnswer;
          jump(document.getElementById(Id), {
            duration: 700,
            callback: () => this.focusElem(nextAnswer)
          });
        }
      }
    }
  }

  // load past exercise
  loadExercise(e, index) {
    e.preventDefault();
    const exercise = this.state.exercises[index];
    this.setState({
      ...this.state,
      exercise: exercise,
      editing: true,
      started: true
    });
    jump(document.getElementById('answer0'), {
      duration: 700,
      callback: () => this.focusElem('0')
    });
  }

  // delete past exercise
  deleteExercise(e, index) {
    e.preventDefault();
    const exercises = this.state.exercises;
    const exercise = exercises[index];
    const current =
      exercise.date === this.state.exercise.date
        ? { date: Date.now(), answers: [''] }
        : this.state.exercise;
    this.setState({
      exercises: [...exercises.slice(0, index), ...exercises.slice(index + 1)],
      exercise: current,
      currentAnswer: 0
    });
  }

  // format past exercise date
  formatDate(date) {
    const end = new Date(date);
    const day = end.getDate();
    const month = end.getMonth();
    const year = end.getFullYear();
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];
    return `${months[month]} - ${day < 10 ? '0' : ''}${day} - ${year}`;
  }

  shareTwitter() {
    window.open('https://twitter.com/intent/tweet?text=www.7levelsdeep.com');
  }

  shareFacebook() {
    window.open(
      'https://www.facebook.com/dialog/share?app_id=1656429184383922&display=popup&href=http://www.7levelsdeep.com&redirect_uri=http://www.7levelsdeep.com/'
    );
  }

  shareLinkedin() {
    window.open(
      'http://www.linkedin.com/shareArticle?mini=true&url=http://www.7levelsdeep.com&title=Discover%20Your%20Why&description=A%20short%20exercise%20to%20help%20discover%20your%20why'
    );
  }

  shareEmail() {
    window.open('mailto:?subject=Discover Your Why&body=www.7levelsdeep.com');
  }

  render() {
    const { currentAnswer, editing, exercise, exercises, hasMounted } = this.state;
    const { answers } = exercise;

    let lastButton;
    if (answers.length === 7 && answers[6] !== '') {
      if (editing) {
        lastButton = (
          <button className="last-button" title="save exercise" type="submit">
            SAVE
          </button>
        );
      } else {
        lastButton = (
          <button className="last-button" title="finish exercise" type="submit">
            FINISH
          </button>
        );
      }
    }

    return (
      <div id="website" className="website">
        <section className="intro">
          <div className="home-screen">
            <h1 className="title">7 Levels Deep</h1>
            <h2 className="description">Discover Your Why</h2>
            <div className="start-buttons">
              <div className="start" />
              <div className="start-button" title="start exercise" onClick={this.startExercise}>
                START EXERCISE
              </div>
              <div className="enter-help">
                press <strong>ENTER</strong>
              </div>
            </div>
          </div>
        </section>
        <section id="exercise" className="why">
          <div className="instruction-info">
            <div className="why-title">WHY?</div>
            <div className="why-description">Discover what drives you to take action</div>
          </div>
        </section>
        <section className="instructions">
          <div className="instruction-info">
            <div className="instructions-title">INSTRUCTIONS</div>
            <div className="instructions-description">
              Take 5-10 minutes to reflect and answer 7 questions
            </div>
          </div>
        </section>
        <section className="exercise">
          <div className="small-info">
            <div className="current-exercise">
              {answers.map((answer, index) => {
                const answerID = 'answer' + index;
                return (
                  <div className="answer-div" key={index} id={answerID}>
                    <form className="answer-form" onSubmit={e => this.next(e, index)}>
                      <div className="prompt">
                        <div className="number">{index + 1}.</div>{' '}
                        <div className="question">{questions[index]}</div>
                      </div>
                      <div className="answer-section">
                        <textarea
                          className="answer"
                          type="text"
                          id={index}
                          name={index}
                          value={answer}
                          onKeyDown={e => this.handleEnter(e, index)}
                          onChange={e => this.changeAnswer(e, index)}
                          onFocus={e => this.setCurrent(e, index)}
                          autoComplete="off"
                        />
                      </div>
                      {answer !== '' &&
                        currentAnswer === index &&
                        index !== 6 && (
                          <button className="next-button" type="submit">
                            press ENTER
                          </button>
                        )}
                      {index === 6 && lastButton}
                    </form>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {hasMounted && exercises.length > 0 ? (
          <section id="past">
            <div className="info">
              <div className="exercise-content">
                <div className="exercise-title">Past Exercises</div>
                <div className="past-exercises">
                  {exercises.map((exercise, index) => (
                    <div className="past-exercise" key={index}>
                      <div className="exercise-date" onClick={e => this.loadExercise(e, index)}>
                        {exercise.answers[0]}
                      </div>
                      <button
                        className="exercise-download"
                        onClick={e => {
                          e.preventDefault();
                          download(exercise);
                        }}
                        title="Download answer"
                      >
                        <Download />
                      </button>
                      <button
                        className="exercise-delete"
                        onClick={e => this.deleteExercise(e, index)}
                        title="Delete exercise"
                      >
                        <Delete />
                      </button>
                    </div>
                  ))}
                </div>
                <button className="take-again" onClick={this.startExercise} title="Start exercise">
                  START NEW EXERCISE
                </button>
              </div>
            </div>
          </section>
        ) : null}
        {hasMounted && exercises.length > 0 ? (
          <section className="share">
            <div className="small-info">
              <div className="share-title">
                <div className="share-title-word">If you found this exercise useful,</div>
                <br /> <div className="share-word">Share It</div>
              </div>
              <div className="social-buttons">
                <button
                  className="social-button"
                  title="Share to twitter"
                  onClick={this.shareTwitter}
                >
                  <Twitter />
                </button>
                <button
                  className="social-button"
                  title="Share to facebook"
                  onClick={this.shareFacebook}
                >
                  <Facebook />
                </button>
                <button
                  className="social-button"
                  title="Share to linkedin"
                  onClick={this.shareLinkedin}
                >
                  <Linkedin />
                </button>
                <button className="social-button" title="send email" onClick={this.shareEmail}>
                  <Email />
                </button>
              </div>
            </div>
          </section>
        ) : null}
        {hasMounted && <section className="rate">
          <a
            className="rate-button"
            title="Rate 7 Levels Deep"
            href="https://itunes.apple.com/us/app/7-levels-deep-discover-your-why/id1214661840?mt=8"
            itemProp="url"
            target="_blank"
            rel="noopener noreferrer"
          >
            RATE 7 LEVELS DEEP
          </a>
        </section>}
        {hasMounted && <section className="app-store">
          <a
            href="https://itunes.apple.com/us/app/7-levels-deep-discover-your-why/id1214661840?mt=8"
            itemProp="url"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="iOS app"
              height={50}
              id="ios"
              src="https://twos.s3.amazonaws.com/appStore.svg"
              title="iOS app"
            />
          </a>
        </section>}
        {hasMounted && <section className="footer">
          <div className="footer-info">
            Made with{' '}
            <div className="heart">
              <Heart />
            </div>{' '}
            by{' '}
            <a
              className="link"
              href="https://www.parkerklein.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Parker Klein
            </a>
          </div>
        </section>}
      </div>
    );
  }
}
