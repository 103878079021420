import React, { Component } from 'react';

class PrivacyPolicyPage extends Component {
  handleEmail() {
    window.open('mailto:parker@reflectwithmuse.com?subject=Privacy', '_blank');
  }

  render() {
    return (
      <div id="website" className="website">
        <div>Privacy Policy</div>
        <div>Last Modified: May 17, 2019</div>
        <div>
          {
            '7 Levels Deep provides an exercise to discover your why. When you use these product—and any others we roll out—you’ll inevitably share some information with us. We get that that can affect your privacy. So we want to be upfront about the information we collect, how we use it, whom we share it with, and the choices we give you to control, access, and update your information. That’s why we’ve written this privacy policy. And it’s why we’ve tried to write it in a way that’s blissfully free of the legalese that often clouds these documents. Of course, if you still have questions about anything in our privacy policy, email us at '
          }
          <span onClick={this.handleEmail}>parker@7levelsdeep.com</span>.
        </div>
        <div>Information We Collect</div>
        <div>
          There are two basic ways we collect information: Information you choose to give us.
          Information we get automatically when you use our product. Here’s a little more detail on
          each of these categories.
        </div>
        <div>Information You Choose to Give Us</div>
        <div>
          When you interact with our product, we collect the information that you choose to share
          with us. For example, you may set up a basic 7 Levels Deep account, so we need to collect
          a few important details about you: your name, an email address, and a password. We also
          collect your answers to questions. Also, when you contact 7 Levels Deep Help or
          communicate with us in any other way, we’ll collect whatever information you volunteer.
        </div>
        <div>Information We Get Automatically When You Use Our Product</div>
        <div>
          When you use our product, we collect information about which of those product you’ve used
          and how you’ve used them. We might know, for instance, how many days in a row you have
          used the product, how many words you type per answer, and how many total questions you
          have answered.
        </div>
        <div>How We Use Information</div>
        <div>
          What do we do with the information we collect? The short answer is: Privately and securely
          store your information, as well as, use it to provide you with a product that we
          relentlessly improve. We develop, improve, deliver, maintain, and protect our product,
          communicate with you, monitor and analyze trends and usage, enhance the safety and
          security of our product, verify your identity and prevent fraud or other unauthorized or
          illegal activity, to enhance the product and your experience with them. We may also store
          some information locally on your device. For example, we may store information as local
          cache so that you can open the app and view content faster. Although we welcome Müsing
          from all over the world, keep in mind that no matter where you live or where you happen to
          use our product, you consent to us processing and transferring information in and to the
          United States and other countries whose data-protection and privacy laws may offer fewer
          protections than those in your home country.
        </div>
        <div>How We Share Information</div>
        <div>
          We may share information about you in the following ways: With service providers, sellers,
          and partners. We may share information about you with service providers who perform
          product on our behalf, sellers that provide goods through our product, and business
          partners that provide product and functionality. With third parties for legal reasons. We
          may share information about you if we reasonably believe that disclosing the information
          is needed to: comply with any valid legal process, government request, or applicable law,
          rule, or regulation, investigate, remedy, or enforce potential Terms of Service
          violations, protect the rights, property, and safety of us, our users, or others, or
          detect and resolve any fraud or security concerns. With third parties as part of a merger
          or acquisition. If 7 Levels Deep gets involved in a merger, asset sale, financing, or
          acquisition of some portion of our business to another company, we may share your
          information with that company before and after the transaction closes. In the aggregate.
          We may also share with third parties, such as advertisers, aggregated or de-identified
          information that cannot reasonably be used to identify you.
        </div>
        <div>Analytics and Advertising Product Children</div>
        <div>
          We don’t direct our Product to anyone under 13. And that’s why we do not knowingly collect
          personal information from anyone under 13.
        </div>
        <div>Revisions to the Privacy Policy</div>
        <div>
          We may change this privacy policy from time to time. But when we do, we’ll let you know
          one way or another. Sometimes, we’ll let you know by revising the date at the top of the
          privacy policy that’s available on our website and mobile application. Other times, we may
          provide you with additional notice (such as adding a statement to our websites’
          homepages).
        </div>
        <div>Questions</div>
        <div>
          If you have any questions regarding our Privacy Policy, please contact{' '}
          <span onClick={this.handleEmail}>parker@7levelsdeep.com</span>.
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyPage;
