import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import Privacy from './components/Privacy';

export default (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/privacy" component={Privacy} />
    <Route path="*" component={Home} />
  </Switch>
);
